import research_0 from "./research/research_0";
import research_1 from "./research/research_1";
import review_0 from "./reviews/review_0";
import review_1 from "./reviews/review_1";

let featured = [
    research_0,
    research_1,
    review_0,
    review_1
];

export default featured;