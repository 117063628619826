import React, { createContext, useState, useEffect, useContext } from 'react';

const WindowWidthContext = createContext(undefined);

export const WindowWidthProvider = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <WindowWidthContext.Provider value={windowWidth}>
            {children}
        </WindowWidthContext.Provider>
    );
};

export const useWindowWidth = () => {
    const context = useContext(WindowWidthContext);
    if (context === undefined) {
        throw new Error('useWindowWidth must be used within a WindowWidthProvider');
    }
    return context;
};
