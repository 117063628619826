import { useState, useEffect } from "react";

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Services ----
import { time_ago } from "../services/helper_functions"

import cross_white from "../images/icons/cross_white.png";

let sample_img = "https://cdn.midjourney.com/0a0cf7f2-eb2e-400c-ae4a-a294994236e5/0_2.png";

function News_Content({data}) {

    let [show_ad, set_show_ad] = useState(false);
    let [animate_out, set_animate_out] = useState(false);

    // useEffect(()=>{
    //     setTimeout(function() {
    //         set_show_ad(true);
    //     },20000)
    // },[]);

    function close_ad() {
        set_animate_out(true);
        setTimeout(function() {
            set_show_ad(false);
            set_animate_out(false);
        },250);
    }

    let outlink = "https://jetbolt.io/?source=tvn_pop_up"

    function handle_outlink() {
        window.gtag('event', 'conversion', {
            'send_to': "AW-16555645576/eGnuCM_k9bwZEIi1rNY9",
            value: 1.0,
            currency: 'USD',
        });
    
        window.open(outlink);
    }

    const window_width = useWindowWidth();

    let outer_container_width = 800;
    if (window_width <= 1220) {
        outer_container_width = window_width - 420;
    }
    if (window_width <= 800) {
        outer_container_width = window_width - 20;
    }

    let styles = {

        container: {
            position: "relative",
            display: "inline-block",
            width: "100%",
            marginRight: "0px",
            marginTop: "20px",
            // borderRadius: "20px",
            overflow: "hidden",
            // border: `1px solid ${colors.light_gray}`,
            // backgroundColor: colors.light_gray,

            ...(window_width <= 800 && {
                width: "100%",
                marginRight: "0px",
                marginTop: "0px",
                backgroundColor: "white",
                borderRadius: "0px",
            })
        },

        image: {
            width: "100%"
        },

        text_container: {
            padding: "20px",
            width: "calc(100% - 40px)",

            ...(window_width <= 800 && {
                paddingLeft: "0px",
                paddingRight: "0px",
                width: "calc(100% - 0px)"
            })
        }
    }

    return (
        <div style={styles.container}>
            { data.title && 
            <>
            { window_width <= 800 && 
                <>
                    <h1>{data.title}</h1>
                    <p style={{fontSize: "12px"}}>Posted {time_ago(data.date)}</p>
                    <br/>
                </>
            }
            {data.image && !data.hide_banner && <img style={styles.image} src={data.image}/>}
            <div style={styles.text_container}>
                { window_width > 800 && 
                    <>
                        <h2>{data.title}</h2>
                        <p style={{fontSize: "14px"}}>Posted {time_ago(data.date)}</p>
                        <br/>
                    </>
                }
                <p>{data.content}</p>
                
            </div>
            </>
            }
            <div className="pop_over_shield" style={{display: show_ad?"block":"none"}}></div>
            <div className={`pop_over_ad ${animate_out ? "hide":""}`} style={{display: show_ad?"block":"none"}}>
                <div className="pop_over_title">JetBolt Token</div>
                <img src="https://i.imgur.com/Oqy6Ag1.png" className="full_img"/>
                <div className="pop_over_text">JetBolt aims to power the next generation of crypto apps. Join now and be part of the future of crypto.</div>
                <div className="visit_site_button" onClick={handle_outlink}>Learn More</div>
                <div className="continue_reading_button" onClick={close_ad}>Continue Reading</div>
                <img className="pop_over_close" src={cross_white} onClick={close_ad}/>
            </div>
        </div>
    )

}

export default News_Content;