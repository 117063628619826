import { get_country_code } from "../../services/location";
import coins_map from "./data/coins_map.json";
import tick_icon from "../../images/icons/tick.png";
import tick_pro from "../../images/icons/tick_pro.png";
import cross_pro from "../../images/icons/cross_pro.png";

let _data_file;
let _article_index;
let _country_code;

function token_nav(index, token) {
    if (token.is_sponsored) {
        window.gtag('event', 'conversion', {
            'send_to': "AW-16555645576/eGnuCM_k9bwZEIi1rNY9",
            value: 1.0,
            currency: 'USD',
        });
        window.open(`https://jetbolt.io/?source=tvn_${_data_file}_${_article_index}_${_country_code}`);
    }
    else {
        document.querySelector(`#full_section_${index}`).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
}

export function make_listicle(data_file, article_index, is_review=true) {

    _data_file = data_file;
    _article_index = article_index;

    let article_data = require(`./data/${data_file}.json`);

    let tokens = [];
    for (let i = 0; i < article_data.tokens.length; i++) {
        let name = article_data.tokens[i].name;
        let is_sponsored = article_data.tokens[i].is_sponsored;
        let token = coins_map[name];
        token.is_sponsored = is_sponsored;
        tokens.push(token)
    }    

    return {
        "title": article_data.title,
        "date": Date.now() / 1000 - 60*60*25,
        "image": require(`../../images/cover/${article_data.image}.png`),
        "summary": article_data.summary,
        "hide_banner": true,
        "link": `/${is_review?"reviews":"research"}/${article_index}/${article_data.title.replaceAll(" ","-").toLowerCase()}`,
        "content": <>
        <section>
            <p>{article_data.intro}</p><br/>
        </section>
        <section>
    
            {
                tokens.map((token, index)=>(
                    <div className={`token_box ${token.is_sponsored?"blue":""}`} key={index}>
                        <div className={`rank_indicator_simple ${token.is_sponsored?"blue":""}`}>{index+1}</div>
                        <div className={`rank_indicator_extra_text ${token.is_sponsored?"blue":""}`}>{token.header_title}</div>
                        <div className="token_box_desc">
                            <h2>
                                <img className="token_icon" src={token.icon}/>
                                {token.name}
                            </h2>
                        </div>
                        <div className="vs_button" onClick={()=>token_nav(index, article_data.tokens[index])}>Learn More</div>
                        <div className="extra_spacer"></div>
                        {
                            token.usps.map((usp, index)=>(
                                <div className="tick_reason" key={index}>
                                    <img className="tick_icon" src={tick_icon}/>
                                    {usp}
                                </div>
                            ))
                        }
                        <div className="vs_button_mobile" onClick={()=>token_nav(index, article_data.tokens[index])}>Learn More</div>
                    </div>
                ))
            }
        </section>
        <div className="extra_spacer"></div>
        <div className="extra_spacer"></div>
        <section>
            <div className="token_box thin purple">
                <h2>{article_data.list_title}</h2>
                <p>{article_data.list_description}</p>
                <br/>
    
                <ol className="ul_spec">
                    {
                        tokens.map((token, index) => (
                            <>
                            <li>{index+1}. <a className="highlighted_text" onClick={()=>token_nav(index, article_data.tokens[index])}>{token.name} ({token.symbol})</a> - {token.short_summary}</li>
                            <br/>
                            </>
                        ))
                    }
                </ol>
            </div>
        </section>
        <div className="extra_spacer"></div>
        <div className="extra_spacer"></div>
        <section>
            <h2>{article_data.detail_section_title}</h2>
            <br/>
            <p>{article_data.detail_section_intro}</p>
        </section>
    
        {
            tokens.map((token, index) => (
                <section className="full_token_section" key={index} id={`full_section_${index}`}>
                    <h2>
                        <img src={token.icon} className="token_icon"/>
                        {index+1}. {token.name} ({token.symbol})
                    </h2>
                    <br/>
                    <p>{token.full_description}</p>
                    <br/>
                    <img className="full_img_w" src={token.banner}/>
                    <div className="pros_cons_container">
                        <div className="tick_reason"><h3>Pros</h3></div>
                        {
                            token.pros.map((usp, index)=>(
                                <div className="tick_reason" key={index}>
                                    <img className="tick_icon" src={tick_pro}/>
                                    {usp}
                                </div>
                            ))
                        }
                    </div>
                    <div className="pros_cons_container">
                        <div className="tick_reason"><h3>Cons</h3></div>
                        {
                            token.cons.map((usp, index)=>(
                                <div className="tick_reason" key={index}>
                                    <img className="tick_icon" style={{opacity: "0.8"}} src={cross_pro}/>
                                    {usp}
                                </div>
                            ))
                        }
                    </div>
                    <div className="extra_spacer"></div>
                    <table className="full_table">
                        <tr>
                            <th>Name</th>
                            <td>{token.name}</td>
                        </tr>
                        <tr>
                            <th>Symbol</th>
                            <td>{token.symbol}</td>
                        </tr>
                        <tr>
                            <th>Chain</th>
                            <td>{token.blockchain}</td>
                        </tr>
                        <tr>
                            <th>Launch Date</th>
                            <td>{token.launch_date}</td>
                        </tr>
                        {
                            token.is_sponsored &&
                            <tr>
                                <th>Official Site</th>
                                <td><u className="highlighted_text" onClick={()=>token_nav(index, article_data.tokens[index])}>{token.official_site}</u></td>
                            </tr>
                        }
                    </table>
                    {
                        token.is_sponsored &&
                        <div className="visit_nsite_button" onClick={()=>token_nav(index, article_data.tokens[index])}>Visit {token.name} Site</div>
                    }
                </section>
            ))
        }
    
        <section>
            <h2>{article_data.conclusion_title}</h2><br/>
            <p>{article_data.conclusion}</p>
        </section>
        </>
    }
}

(async function () {
    _country_code = await get_country_code()
})()