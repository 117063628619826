import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Components ----
import News_Content from "./news_content";
import Email_Sign_Up from "./email_signup";

// ---- Content ----
import featured from "../content/featured";
import all_reviews from "../content/reviews/index";
// import all_reviews from "../content/reviews_g/index";
import all_research from "../content/research/index";

// ---- Services ----
import { make_ticker_news, make_latest_news } from "../services/dynamic_news";
// import { make_ticker_news, make_latest_news } from "../services/dynamic_news_g";

function Article_Section({ category, type }) {

    let [content, set_content] = useState({});
    const { article_number, title_string } = useParams();

    useEffect(()=>{
        if (type == "reviews") {
            set_content(all_reviews[parseInt(article_number)]);
        }
        if (type == "research") {
            set_content(all_research[parseInt(article_number)]);
        }
        if (type == "ticker") {
            (async function() {
                let data = await make_ticker_news(article_number.toUpperCase());
                set_content(data);
            })();
        }
        if (type == "all") {
            (async function() {
                let data = await make_latest_news();
                set_content(data);
            })();
        }
    },[article_number])

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            // marginTop: "20px"
            // backgroundColor: colors.dark_blue
            ...(window_width <= 550 && {
                marginTop: "20px"
            })
        },

        inner_container: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "800px",
            marginLeft: "calc(50% - 600px)",
            // backgroundColor: "red",

            ...(window_width <= 1240 && {
                width: "calc(100% - 440px)",
                marginLeft: "20px",
            }),

            ...(window_width <= 800 && {
                width: "calc(100% - 40px)",
                marginLeft: "20px",
            })
        },

        side_bar: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "380px",
            marginLeft: "20px",

            ...(window_width <= 800 && {
                display: "none"
            })
        },

        side_bar_inner: {
            borderRadius: "20px",
            marginTop: "20px",
            // backgroundColor: colors.light_gray,
            border: `1px solid ${colors.light_gray}`,
            padding: "20px",
            width: "calc(100% - 40px)"
        },

        indent_left: {
            marginLeft: "20px"
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.inner_container}>
                {/* { window_width > 800 && <h1>{type == "ticker" ? article_number.toUpperCase() + " News" : category}</h1> } */}
                <News_Content data={content}/>
            </div>
            <div style={styles.side_bar}>

                {/* <h1>Sidebar</h1> */}
                <div style={styles.side_bar_inner}>
                    <h3>Newsletter</h3>
                    <Email_Sign_Up/>
                    <p style={{fontSize: "14px"}}>By signing up to our newsletter, you hereby agree to our Privacy Policy.</p>
                    <br/>
                    <h3>Featured</h3>
                    <br/>
                    <ul style={styles.indent_left}>
                        {
                            featured.map((item, index) => (
                                <u  style={{fontSize: "14px"}} className="clickable_text" onClick={()=>window.location=item.link}><li key={index}>{item.title}</li></u>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Article_Section;