import { useEffect } from "react";

import Header from "../components/header";
import News_Section from "../components/news_section";
import Pre_Footer from "../components/pre_footer";
import Footer from "../components/footer";

function Article_List_Page({ title, type }) {
    return (
        <>
            <Header/>
            <News_Section title={title} type={type}/>
            <Pre_Footer/>
            <Footer/>
        </>
    )
}

export default Article_List_Page;