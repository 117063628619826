import { useState, useEffect } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Components ----
import News_Item from "./news_item";
import Email_Sign_Up from "./email_signup";

// ---- Services ----
import { get_latest_news } from "../services/api";

// ---- Content ----
import featured from "../content/featured";
import all_reviews from "../content/reviews/index";
// import all_reviews from "../content/reviews_g/index";
import all_research from "../content/research/index";

function News_Section({ title, type }) {

    let [content, set_content] = useState([]);

    useEffect(() => {

        (async function() {

            if (type == "news") {
                let data = await get_latest_news();
                set_content(data);
            }
            if (type == "reviews") {
                set_content(all_reviews);
            }
            if (type == "research") {
                set_content(all_research);
            }

            

        })();

    },[])

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            marginTop: "20px"
            // backgroundColor: colors.dark_blue
        },

        inner_container: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "800px",
            marginLeft: "calc(50% - 600px)",
            // backgroundColor: "red",

            ...(window_width <= 1240 && {
                width: "calc(100% - 440px)",
                marginLeft: "20px",
            }),

            ...(window_width <= 800 && {
                width: "calc(100% - 20px)",
                marginLeft: "10px",
            })
        },

        side_bar: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "380px",
            marginLeft: "20px",

            ...(window_width <= 800 && {
                display: "none"
            })
        },

        side_bar_inner: {
            borderRadius: "20px",
            marginTop: "20px",
            backgroundColor: colors.light_gray,
            padding: "20px",
            width: "calc(100% - 40px)"
        },

        indent_left: {
            marginLeft: "20px"
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.inner_container}>
                <h1>{title}</h1>
                {
                    content.map((item, index) => (
                        <>
                        {
                            type == "news" &&
                            <News_Item key={index} index={index} title={item.title} summary={item.summary} image={item.image} date={item.date} link={item.link}/>
                        }
                        {
                            type == "reviews" &&
                            <News_Item key={index} index={index} title={item.title} summary={item.summary} image={item.image} date={item.date} link={item.link}/>
                        }
                        {
                            type == "research" &&
                            <News_Item key={index} index={index} title={item.title} summary={item.summary} image={item.image} date={item.date} link={item.link}/>
                        }
                        </>
                    ))
                }
            </div>
            <div style={styles.side_bar}>

                <h1>Sidebar</h1>
                <div style={styles.side_bar_inner}>
                    <h2>Newsletter</h2>
                    <Email_Sign_Up/>
                    <p>By signing up to our newsletter, you hereby agree to our Privacy Policy.</p>
                    <br/>
                    <h2>Featured</h2>
                    <br/>
                    <ul style={styles.indent_left}>
                        {
                            featured.map((item, index) => (
                                <u className="clickable_text" onClick={()=>window.location=item.link}><li key={index}>{item.title}</li><br/></u>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default News_Section;