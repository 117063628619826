import { useState, useEffect, useRef } from 'react';

// ---- Icons ----
import down_icon from "../images/icons/down.png";
import right_icon from "../images/icons/right.png";
import cross_icon from "../images/icons/cross.png";

// ---- Constants ----
import colors from "../constants/colors";
import { languages } from "../constants/languages";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';
import { useModal } from "../services/modal_management";

// ---- Services ----
import { initialize_translator, set_translation } from "../services/language";

function Language_Selector({ close_modal, parameters }) {

    let [searchable_languages, set_searchable_languages] = useState([...languages]);

    let search_input = useRef();

    function search() {
        let search_query = search_input.current.value.toLowerCase();

        let filtered_items = languages.filter((item) => {
            return (
                item.name.toLowerCase().startsWith(search_query) ||
                item.iso_code.toLowerCase().startsWith(search_query)
            );
        });

        set_searchable_languages(filtered_items); 
    }

    async function choose_lang(language) {
        parameters.set(language);

        (async function() {
            await initialize_translator();
            await set_translation(language.iso_code);
        })();
        
        close_modal();
    }

    const window_width = useWindowWidth();

    let styles = {
        close_modal: {
            position: "absolute",
            top: "20px",
            left: "20px",
            width: "40px",
            height: "40px"
        },

        modal_title: {
            width: "100%",
            height: "50px",
            lineHeight: "35px",
            marginBottom: "20px",
            textAlign: "center",
            borderBottom: `1px solid ${colors.light_gray}`
        },

        search_bar: {
            width: "calc(100% - 30px)",
            height: "30px",
            backgroundColor: "rgb(233, 233, 233)",
            padding: "10px",
            paddingLeft: "15px",
            paddingRight: "15px",
            lineHeight: "30px",
            border: "none",
            borderRadius: "0px"
        },

        list_container: {
            marginTop: "20px",
            marginLeft: "-8px",
            width: "calc(100% - 4px)",
            height: window_width > 640 ? "400px" : "calc(100% - 140px)",
            overflow: "scroll",
            padding: "10px",
            paddingTop: "2px",
        },

        list_item: {
            position: "relative",
            marginBottom: "20px",
            width: "100%",
            borderRadius: "0px",
            height: "50px",
            border: `1px solid ${colors.light_gray}`,
            backgroundColor: "white",
            cursor: "pointer"
        },

        icon: {
            position: "absolute",
            left: "5px",
            top: "5px",
            width: "40px",
            height: "40px"
        },

        right_icon: {
            position: "absolute",
            right: "5px",
            top: "15px",
            width: "20px",
            height: "20px",
            opacity: "0.25"
        },

        label: {
            position: "absolute",
            left: "55px",
            top: "5px",
            lineHeight: "40px",
            fontWeight: "bold"
        }
    }

    return (
        <>
            <div>

                <img style={styles.close_modal} src={cross_icon} onClick={close_modal}/>
                <div style={styles.modal_title}>
                    <h3>Choose Language</h3>
                </div>

                <input ref={search_input} style={styles.search_bar} placeholder="Search language" onChange={search}/>
                <div style={styles.list_container}>
                    {
                        searchable_languages.map((language, index) => (
                            <div style={styles.list_item} onClick={()=>choose_lang(language)} className="notranslate">
                                <img style={styles.icon} src={language.icon}/>
                                <div className="clickable_text" style={styles.label}>{language.native_name}</div>
                                <img style={styles.right_icon} src={right_icon}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Language_Selector;