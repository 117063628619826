import { useState, useEffect } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Components ----
import Header from "../components/header";
import Pre_Footer from "../components/pre_footer";
import Footer from "../components/footer";
import News_Content from "../components/news_content";
import Email_Sign_Up from "../components/email_signup";

// ---- Content ----
import featured from "../content/featured";

function Terms() {

    let [content, set_content] = useState({
        "title": "Terms of Use",
        "date": 1714376623,
        "content": <>
        <p>Terms of Use and Service. If you are using an automatic language translation service to read this text, in case of doubt, only the original English text prevails.</p>
        <p><br/></p>
        <p><strong>OVERVIEW</strong></p>
        <br/><p>PLEASE READ THESE TERMS OF USE &amp; SERVICE CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION AND AFFECT YOUR LEGAL RIGHTS. THEY INCLUDE A MANDATORY ARBITRATION AGREEMENT AND CLASS ACTION WAIVER, WHICH (WITH LIMITED EXCEPTIONS) REQUIRE ANY DISPUTES BETWEEN US TO BE RESOLVED THROUGH INDIVIDUAL ARBITRATION RATHER THAN BY A JUDGE OR JURY IN COURT.</p>
        <br/><p>Thank you for visiting this page. Terms of Use &amp; Service include the terms of use of this website, our Twitter account, Discord server, our Telegram Channel, our Facebook and Instagram account. Please read these terms carefully and thoroughly.</p>
        <br/><p>You are bound by the terms of use when you visit our website and other online services that we provide.&nbsp;</p>
        <br/><p>HYPERION CAPITAL HOLDINGS LTD operates this website techvoyagernews.com. Throughout the site, the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to HYPERION CAPITAL HOLDINGS LTD, also referred to as &ldquo;HYPERION&rdquo; or the &ldquo;Company&rdquo;, techvoyagernews.com, or the brand name used for the specific services described in this website. We offer this website, and our services, including all information, tools, and services available to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here.</p>
        <br/><p>By visiting or using our site and/or purchasing something from us, you engage in our &ldquo;Service&rdquo; and agree to be bound by the following terms and conditions (&ldquo;Terms of Use &amp; Service,&rdquo; &ldquo;Terms&rdquo;), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including, without limitation, users who are visitors, browsers, vendors, and/or customers.</p>
        <br/><p>Please read these Terms of Use &amp; Service carefully before accessing or using our website or buying any of our products and services. By accessing or using any part of the site and/or buying any of our Products, you agree to be bound by these Terms of Use &amp; Service. If you do not agree to all the terms and conditions of this agreement, you may not access the website or use or purchase any of our Products and Services. You also agree to our Privacy Policy.&nbsp;</p>
        <br/><p>Any new features or tools added to the current site shall also be subject to the Terms of Use &amp; Service. You can review the most current version of the Terms of Use &amp; Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Use &amp; Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website and Service after posting any changes constitutes acceptance of those changes.</p>
        <br/><p>Our website is hosted on GoogleCloud</p>
        <p><br/></p>
        <p><strong>SECTION 1 - ONLINE TERMS</strong></p>
        <br/><p>By agreeing to these Terms of Use &amp; Service, you represent that you are at least the age of majority in your country of residence.</p>
        <br/><p>You may not use our products and/or services for any illegal or unauthorized purpose, nor may you, in using the Service, violate any laws in your jurisdiction.</p>
        <br/><p>The headings in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
        <p><br/></p>
        <p><strong>SECTION 2 - GENERAL CONDITIONS</strong></p>
        <br/><p>You must not transmit any worms or viruses or any code of a destructive nature when using our website or Services.&nbsp;</p>
        <br/><p>You agree not to commercially reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided without express written permission by us.</p>
        <br/><p>You must not infringe upon or violate our intellectual property rights, upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service, collect or track the personal information of others, spam, phish, pharm, pretext, spider, crawl, or scrape, interfere with or circumvent the security features of the Service.</p>
        <br/><p>We may also restrict you from using the service if we suspect that you are using bots to access our system or if we suspect that you are attempting to access our services from a blocked country.</p>
        <br/><p>A breach or violation of any of the Terms may result in an immediate termination of our Services to you.</p>
        <p><br/></p>
        <p><strong>SECTION 3 - ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION</strong></p>
        <br/><p>DISCLAIMER:&nbsp;</p>
        <br/><p>The information content (texts and picture) provided may at times be generated by Artificial Intelligence (AI). As such, we cannot guaranty that the content is accurate, complete, unbiased, fair, non-discriminatory, appropriate, ethical, current, up to date, or free of rights, even though our systems have been designed to use trustworthy sources and verify the content.</p>
        <br/><p>If you identify some issues with the quality of the content provided, please use the FLAG button to alert us. If you encounter serious issues with our content, please contact us promptly by email at support@hypecapital.co</p>
        <br/><p>Meanwhile, you agree that we are not responsible if the information content made available on this site and on our social media posts is not accurate, complete, unbiased, fair, non-discriminatory, appropriate, ethical, current, up to date, or free of rights. The material on this site is provided for general information only. It should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, complete, or timely sources of information. Any reliance on the material on this site is at your own risk. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site.&nbsp;</p>
        <p><br/></p>
        <p><strong>Furthermore, the information provided is not investment advice.</strong></p>
        <p><br/></p>
        <p><strong>SECTION 4 - MODIFICATIONS TO THE SERVICES</strong></p>
        <br/><p>Information and services are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or any third party for any modification, suspension, or discontinuance of the Service.</p>
        <br/><p>We do not warrant that the quality of the services, information, or other material obtained by you will meet your expectations or that any errors in the Service will be corrected.</p>
        <br/><p>We also may run a Discord server and a Telegram channel for information, promotion, and/or members&apos; discussions. Please note that we reserve the right to reject or ban any member infringing on our rules. We may block or ban the access of anyone we see fit for any reason and without any warning or notice. We may terminate such server and/or channel at any time at our discretion and without prior notice.</p>
        <p><br/></p>
        <p><strong>SECTION 5 - THIRD-PARTY LINKS</strong></p>
        <br/><p>Certain content, and services available via our Service may include third-party materials. Third-party links on this site may direct you to third-party websites not affiliated with us. We are not responsible for examining or evaluating their content or accuracy, and we do not warrant it. We will not have any liability or responsibility for any third-party materials or websites or any other materials, products, or services of third parties. We are not liable for any harm or damages related to purchasing or using goods, services, resources, content, or other transactions connected with third-party websites. Please review the third-party&apos;s policies and practices carefully and ensure you understand them before engaging in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third party.</p>
        <p><br/></p>
        <p><strong>SECTION 6 - PERSONAL INFORMATION</strong></p>
        <br/><p>Our Privacy Policy governs your submission of personal information through the store. Please visit the Privacy section of our website. By agreeing to these Terms, you also agree to our Privacy Policy.</p>
        <p><br/></p>
        <p><strong>SECTION 7 - ERRORS</strong></p>
        <br/><p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies, or omissions. We undertake no obligation to update, amend or clarify information in the Service or any related website, except as required by law. No specified update or refresh date applied in the Service or on any related website should be taken to indicate that all information in the Service or related website has been modified or updated.</p>
        <p><br/></p>
        <p><strong>SECTION 8 &ndash; RESTRICTIONS</strong></p>
        <p><br/></p>
        <p><strong>You may not use any of our Services if you are a resident, citizen, or taxpayer of any of the following countries/territories under international sanctions: Afghanistan, Belarus, Cuba, Iran, Democratic People&rsquo;s Republic of Korea (North Korea), Republic of Sudan (Sudan), Republic of South Sudan (South Sudan), Syria, Venezuela, the Russian Federation (Russia), Crimea Region of Ukraine. You may not use any of our Services if you are a person or entity under international sanctions, such as under the US Treasury list or the EU person sanctions list.</strong></p>
        <p><br/></p>
        <p><strong>SECTION 9 - DISCLAIMER:</strong></p>
        <p><br/></p>
        <p><strong>Waiver of liability:</strong></p>
        <br/><p>You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and services delivered to you through the service are (except as expressly stated by us) provided &apos;as is&apos; and &apos;as available&apos; for your use, without any representation, warranties, or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
        <br/><p>In no case shall we, our directors, officers, employees, affiliates, agents, contractors, interns, shareholders, suppliers, service providers, or licensees be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation, lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service or from us, or for any other claim related in any way to your use of the Service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content posted, transmitted, or otherwise made available via the Service, even if advised of their possibility. Because some countries, states, or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
        <p><br/></p>
        <p><strong>Service continuity:</strong></p>
        <br/><p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable. You agree that from time to time, we may remove the Service for indefinite periods of time or cancel the Service at any time without notice to you.&nbsp;</p>
        <p><br/></p>
        <p><strong>Information provided &amp; Risks</strong></p>
        <p><br/></p>
        <p><strong>The information provided by us does not constitute investment advice. Any reliance on such information is strictly at your own risk.</strong></p>
        <p><br/></p>
        <p><strong>SECTION 10 &ndash; INDEMNIFICATION</strong></p>
        <br/><p>You agree to indemnify, defend and hold harmless us and our parent company, subsidiaries, affiliates, partners, officers, directors, shareholders, agents, contractors, licensees, service providers, subcontractors, suppliers, interns, and employees harmless from any claim or demand, including reasonable attorneys&rsquo; fees, made by any third-party due to or arising out of your breach of these Terms of Use &amp; Service or the documents they incorporate by reference or your violation of any law or the rights of a third-party.</p>
        <p><br/></p>
        <p><strong>SECTION 11 &ndash; SEVERABILITY</strong></p>
        <br/><p>If any provision of these Terms of Use &amp; Service is deemed unlawful, void, or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law. The unenforceable portion shall be deemed to be severed from these Terms of Use &amp; Service; such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
        <p><br/></p>
        <p><strong>SECTION 12 - ENTIRE AGREEMENT</strong></p>
        <br/><p>The failure of us to exercise or enforce any right or provision of these Terms of Use &amp; Service shall not constitute a waiver of such right or provision.</p>
        <br/><p>These Terms of Use &amp; Service and any policies or operating rules posted by us on this site or in respect to The Service constitute the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use &amp; Service).</p>
        <br/><p>Any ambiguities in interpreting these Terms of Service shall not be construed against the drafting party.</p>
        <p><br/></p>
        <p><strong>SECTION 13 - GOVERNING LAW &amp; DISPUTES</strong></p>
        <br/><p>These Terms of Use &amp; Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Hong Kong SAR.</p>
        <p><br/></p>
        <p><strong>Waiver:</strong></p>
        <br/><p>The parties (you and us) waive their rights to go to court and have a trial before a judge or a jury. In addition, all claims must be arbitrated or litigated individually and not on a class basis, and claims of more than one customer cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>
        <p><br/></p>
        <p><strong>Claims settlement:</strong></p>
        <br/><p>In case of any dispute, all parties should try to resolve their differences in connection with the Terms of Use &amp; Service or any product or service we provided through informal discussion and negotiation or arbitration, as described below.</p>
        <br/><p>You hereby waive your constitutional, statutory, and otherwise rights to go to court and have a trial before a judge or jury. In addition, all claims must be arbitrated or litigated individually and not on a class basis, and claims of more than one customer cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>
        <br/><p>All claims and disputes that cannot be settled informally or through the Hong Kong small claims tribunal shall be resolved by binding arbitration, as described below. This arbitration agreement applies to you and us, our directors, officers, employees, affiliates, agents, contractors, interns, shareholders, suppliers, service providers, licensees, successors, or assigns. Before seeking arbitration, the party must send a Notice of Dispute describing the nature and basis of the claim or dispute and the requested relief. A Notice of Dispute to HYPERION CAPITAL HOLDINGS LTD should be sent by email to us. If the claim is unresolved within 30 days, either party may begin an arbitration proceeding. Any dispute, controversy, difference, or claim arising out of or relating to this contract, including the existence, validity, interpretation, performance, breach, or termination thereof or any dispute regarding non-contractual obligations arising out of or relating to it shall be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre (HKIAC) (https://www.hkiac.org/) under the HKIAC Administered Arbitration Rules in force when the Notice of Arbitration is submitted. The law of this arbitration clause shall be the Hong Kong law. The seat of arbitration shall be in Hong Kong. The number of arbitrators shall be three. The arbitration proceedings shall be conducted in English. The decisions of the HKIAC shall be binding on each party and final, and all aspects of the arbitration proceeding, including but not limited to the award of the arbitrator, shall be strictly confidential.&nbsp;</p>
        <p><br/></p>
        <p><strong>SECTION 14 - CHANGES TO TERMS OF USE &amp; SERVICE</strong></p>
        <br/><p>You can review the most current version of the Terms of Use &amp; Service at any time on this page. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Use Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Use &amp; Service constitutes acceptance of those changes.</p>
        <p><br/></p>
        <p><strong>SECTION 15 - CONTACT INFORMATION</strong></p>
        <br/><p>Questions about the Terms of Use &amp; Service should be sent to us at support@hypecapital.co</p>
        </>
    });

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            marginTop: "20px"
            // backgroundColor: colors.dark_blue
        },

        inner_container: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "800px",
            marginLeft: "calc(50% - 600px)",
            // backgroundColor: "red",

            ...(window_width <= 1240 && {
                width: "calc(100% - 440px)",
                marginLeft: "20px",
            }),

            ...(window_width <= 800 && {
                width: "calc(100% - 40px)",
                marginLeft: "20px",
            })
        },

        side_bar: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "380px",
            marginLeft: "20px",

            ...(window_width <= 800 && {
                display: "none"
            })
        },

        side_bar_inner: {
            borderRadius: "20px",
            marginTop: "20px",
            backgroundColor: colors.light_gray,
            padding: "20px",
            width: "calc(100% - 40px)"
        },

        indent_left: {
            marginLeft: "20px"
        }
    }

    return (
        <>
            <Header/>
            <div style={styles.container}>
                <div style={styles.inner_container}>
                    { window_width > 800 && <h1>{content.title}</h1> }
                    <News_Content data={content}/>
                </div>
                <div style={styles.side_bar}>

                    <h1>Sidebar</h1>
                    <div style={styles.side_bar_inner}>
                        <h2>Newsletter</h2>
                        <Email_Sign_Up/>
                        <p>By signing up to our newsletter, you hereby agree to our Privacy Policy.</p>
                        <br/>
                        <h2>Featured</h2>
                        <br/>
                        <ul style={styles.indent_left}>
                            {
                                featured.map((item, index) => (
                                    <u className="clickable_text" onClick={()=>window.location=item.link}><li key={index}>{item.title}</li><br/></u>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <Pre_Footer/>
            <Footer/>
        </>
    )
}

export default Terms;