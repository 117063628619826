import { useState, useEffect } from 'react';

function Attribute() {

    useEffect(()=> {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;

        if (url_params.get("status")) {

            let status = url_params.get("status");
            
            if (status == "add_to_cart") {
                window.gtag('event', 'conversion', {
                    'send_to': "AW-16555645576/rAEpCLPz87wZEIi1rNY9",
                });
            }
            else if (url_params.get("amount")) {
                let amount = parseFloat(url_params.get("amount"));

                if (status == "init_checkout") {
                    window.gtag('event', 'conversion', {
                        'send_to': "AW-16555645576/zL_bCLbz87wZEIi1rNY9",
                        value: amount,
                        currency: 'USD',
                    });
                }
                else if (status == "success") {
                    window.gtag('event', 'conversion', {
                        'send_to': "AW-16555645576/Fp5iCLnz87wZEIi1rNY9",
                        value: amount,
                        currency: 'USD',
                    });
                }
            }
        }

    },[]);

    return (
        <></>
    )
}


export default Attribute;