import { languages } from "../constants/languages";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

setInterval(()=> {
    let translate_object = document.querySelector(".skiptranslate");
    if (translate_object) {
        translate_object.style = "position: fixed; width: 0px; opacity: 0;";
    }

},200);

export async function initialize_translator() {

    new window.google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
    await sleep(100);
    let translate_object = document.querySelector(".skiptranslate");
    translate_object.style = "position: fixed; width: 0px; opacity: 0;";
    console.log(translate_object);
}

export async function set_translation(iso_code) {

    await sleep(100);
    while (!(window.google && window.google.translate && window.google.translate.TranslateElement)) {
        await sleep(50);
    }
    // console.log("A");
    let element = document.getElementById('google_translate_element');
    let instance = element.querySelector(':scope > .goog-te-gadget');
    while (!instance) {
        await sleep(50);
    }
    // console.log("A");
    let select_element = instance.querySelector('select.goog-te-combo');
    while (!select_element) {
        await sleep(50);
    }
    // console.log("A");
    select_element.value = iso_code;
    select_element.dispatchEvent(new Event('change'));
    await sleep(100);
    let translate_object = document.querySelector(".skiptranslate");
    translate_object.style = "position: fixed; width: 0px; opacity: 0;";
    console.log(translate_object);
    // console.log("A");

}

export async function set_translation_to_default() {
    // console.log("HERE")
    let iso_code = navigator.languages[0].substring(0, 2);
    console.log({iso_code})
    let default_language = languages.find(lang => lang.iso_code === iso_code);
    if (!default_language) {
        default_language = languages.find(lang => lang.iso_code === "en");
    }
    await set_translation(default_language.iso_code);
    return default_language;
}