import React, { useState } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Services ----
import { time_ago } from "../services/helper_functions"

let sample_img = "https://cdn.midjourney.com/0a0cf7f2-eb2e-400c-ae4a-a294994236e5/0_2.png";

function News_Item({index, title, summary, image, date, link}) {

    const [is_hovered, set_is_hovered] = useState(false);

    const window_width = useWindowWidth();

    let outer_container_width = 800;
    if (window_width <= 1220) {
        outer_container_width = window_width - 420;
    }
    if (window_width <= 800) {
        outer_container_width = window_width - 20;
    }

    let styles = {

        container: {
            position: "relative",
            display: "inline-block",
            width: index == 0 ? "100%" : "calc(50% - 10px)",
            marginRight: index == 0 ? "0px" : index % 2 != 0 ? "20px" : "0px",
            marginTop: "20px",
            borderRadius: "20px",
            overflow: "hidden",
            backgroundColor: is_hovered ? "#CCCCCC" : colors.light_gray,
            cursor: "pointer",

            ...(window_width <= 800 && {
                width: "100%",
                marginRight: "0px"
            })
        },

        image: {
            height: (index == 0 || window_width <= 800) ? `${1080*outer_container_width/1920}px`: `${1080*(outer_container_width/2 - 10)/1920}px`,
            width: "100%",
            objectFit: "cover"
        },

        text_container: {
            padding: "20px",
            width: "calc(100% - 40px)"
        },

        title_text: {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        short_text: {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }

    return (
        <div
            style={styles.container}
            onClick={()=>window.location=link}
            onMouseEnter={() => set_is_hovered(true)}
            onMouseLeave={() => set_is_hovered(false)}
            onTouchStart={() => set_is_hovered(true)}
            onTouchEnd={() => set_is_hovered(false)}
        >
            <img style={styles.image} src={image}/>
            <div style={styles.text_container}>
                <h2 style={styles.title_text}>{title}</h2>
                <p style={styles.short_text}>{summary}</p>
                <br/>
                <p>Posted {time_ago(date)}</p>
            </div>
        </div>
    )

}

export default News_Item;