import review_0 from "./review_0";
import review_1 from "./review_1";
import review_2 from "./review_2";
import review_3 from "./review_3";
import review_4 from "./review_4";
import review_5 from "./review_5";
import review_6 from "./review_6";
import review_7 from "./review_7";
import review_8 from "./review_8";
import review_9 from "./review_9";
import review_10 from "./review_10";

import review_11 from "./review_11";
import review_12 from "./review_12";
import review_13 from "./review_13";
import review_14 from "./review_14";
import review_15 from "./review_15";
import review_16 from "./review_16";
import review_17 from "./review_17";
import review_18 from "./review_18";
import review_19 from "./review_19";
import review_20 from "./review_20";
import review_21 from "./review_21";
import review_22 from "./review_22";
import review_23 from "./review_23";

let all_reviews = [
    review_0,
    review_1,
    review_2,
    review_3,
    review_4,
    review_5,
    review_6,
    review_7,
    review_8,
    review_9,
    review_10,
    
    review_11,
    review_12,
    review_13,
    review_14,
    review_15,
    review_16,
    review_17,
    review_18,
    review_19,
    review_20,
    review_21,
    review_22,
    review_23
];

export default all_reviews;