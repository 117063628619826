import { useState, useEffect } from 'react';

// ---- Icons ----
import logo_icon from "../images/icons/tvn_logo_black.png";
import down_icon from "../images/icons/down.png";

// ---- Constants ----
import colors from "../constants/colors";
import { languages } from "../constants/languages";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';
import { useModal } from "../services/modal_management";

function Header() {

    let [language, set_language] = useState(languages[0]);
    const [lang_is_hovered, set_lang_is_hovered] = useState(false);

    const { open_modal, close_modal } = useModal();

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            height: "70px",
            // color: "white",
            overflow: "hidden",
            borderBottom: `1px solid ${colors.light_gray}`,

            // ...(window_width <= 550 && {
            //     height: "110px",
            //     borderBottom: `1px solid ${colors.light_gray}`,
            // })
        },

        color_0: {
            position: "absolute",
            width: "100%",
            height: "70px",
            // backgroundColor: "#090059",
            backgroundColor: "white",
        },

        color_1: {
            position: "absolute",
            width: "100%",
            top: "70px",
            height: "69px",
            borderBottom: `1px solid ${colors.light_gray}`
        },

        inner_container: {
            position: "relative",
            width: "1200px",
            marginLeft: "calc(50% - 600px)",
            height: "100%",

            ...(window_width <= 1220 && {
                width: "calc(100% - 20px)",
                marginLeft: "10px",
            })
        },
    
        logo: {
            position: "absolute",
            top: "10px",
            left: "0px",
            height: "50px",
            minWidth: "50px",
            // backgroundColor: "red",
            cursor: "pointer"
        },

        language_selector: {
            position: "absolute",
            top: "10px",
            right: "0px",
            height: "50px",
            minWidth: "80px",
            backgroundColor: lang_is_hovered ? "#c7c7c7" : colors.light_gray,
            cursor: "pointer"
        },

        language_icon: {
            position: "absolute",
            top: "10px",
            left: "10px",
            width: "30px",
            height: "30px"
        },

        language_drop_down_icon: {
            position: "absolute",
            top: "15px",
            right: "10px",
            width: "20px",
            height: "20px"
        },

        center_menu: {
            position: "absolute",
            top: "10px",
            left: "100px",
            width: "calc(100% - 200px)",
            height: "50px",
            // backgroundColor: "green",
            textAlign: "center",

            ...(window_width <= 500 && {
                left: "0px",
                width: "100%",
                top: "75px",
            })
        },

        center_menu_item: {
            textDecoration: "underline",
            display: "inline-block",
            lineHeight: "50px",
            paddingLeft: "10px",
            paddingRight: "10px",

            ...(window_width <= 500 && {
                width: "calc(33.33% - 20px)",
                color: "black",
                lineHeight: "30px",
                fontSize: "14px"
            })
        },
    }

    return (
        <div style={styles.container}>
            <div style={styles.color_0}></div>
            <div style={styles.color_1}></div>
            <div style={styles.inner_container}>
                <img style={styles.logo} src={logo_icon} onClick={()=>window.location="/"}/>
                <div
                    style={styles.language_selector}
                    onClick={()=>open_modal("language_selector", {set:set_language})}
                    onMouseEnter={() => set_lang_is_hovered(true)}
                    onMouseLeave={() => set_lang_is_hovered(false)}
                    onTouchStart={() => set_lang_is_hovered(true)}
                    onTouchEnd={() => set_lang_is_hovered(false)}    
                >
                    <img style={styles.language_icon} src={language.icon}/>
                    <img style={styles.language_drop_down_icon} src={down_icon}/>
                </div>
                <div style={styles.center_menu}>
                    <div className="clickable_text" style={styles.center_menu_item} onClick={()=>window.location="/news"}>News</div>
                    <div className="clickable_text" style={styles.center_menu_item} onClick={()=>window.location="/reviews"}>Reviews</div>
                    <div className="clickable_text" style={styles.center_menu_item} onClick={()=>window.location="/research"}>Research</div>
                </div>
            </div>
        </div>
    )
}

export default Header;