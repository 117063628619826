import research_0 from "./research_0";
import research_1 from "./research_1";
import research_2 from "./research_2";
import research_3 from "./research_3";
import research_4 from "./research_4";
import research_5 from "./research_5";
import research_6 from "./research_6";
import research_7 from "./research_7";
import research_8 from "./research_8";
import research_9 from "./research_9";


let all_research = [
    research_0,
    research_1,
    research_2,
    research_3,
    research_4,
    research_5,
    research_6,
    research_7,
    research_8,
    research_9
];

export default all_research;