import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

// ---- Hooks ----
import { WindowWidthProvider } from "./services/window_width_provider";
import { Modal_Provider } from "./services/modal_management";

// ---- Default Styles ----
import "./constants/default_styles.css";
import "./constants/more_styles.css";

// ---- Pages ----
import Article_List_Page from "./pages/article_list_page";
import Article_Content_Page from "./pages/article_content_page";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Attribute from './pages/attribute';

// https://techvn.co/research/6/how-jetbolt-plans-to-disrupt-the-crypto-space

const ExternalRedirect = ({ to }) => {
  React.useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;  // Render nothing
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WindowWidthProvider>
    <Modal_Provider>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Article_List_Page title={"Top Stories"} type={"news"}/>}/>

            {/* <Route path='/research/6/:title_string' element={<ExternalRedirect to="https://jetbolt.io/?source=fb" />}/> */}

            <Route path="/news" element={<Article_List_Page title={"Top Stories"} type={"news"}/>}/>
            <Route path="/reviews" element={<Article_List_Page title={"Reviews"} type={"reviews"}/>}/>
            <Route path="/research" element={<Article_List_Page title={"Research"} type={"research"}/>}/>

            <Route path="/latest_review" element={<Navigate to="/reviews/0/top-10-drag-and-drop-website-builders" replace />}/>
            <Route path="/latest_research" element={<Navigate to="/research/0/the-rise-of-wearables-analyzing-consumer-adoption-and-market-trends" replace />}/>

            <Route path="/reviews/:article_number/:title_string" element={<Article_Content_Page category={"Reviews"} type={"reviews"}/>}/>
            <Route path="/research/:article_number/:title_string" element={<Article_Content_Page category={"Research"} type={"research"}/>}/>

            <Route path="/ticker/:article_number/:title_string" element={<Article_Content_Page category={"Ticker"} type={"ticker"}/>}/>
            <Route path="/all/:article_number/:title_string" element={<Article_Content_Page category={"All News"} type={"all"}/>}/>

            <Route path="/terms" element={<Terms/>}/>
            <Route path="/privacy" element={<Privacy/>}/>

            <Route path="/attribute" element={<Attribute/>}/>

          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </Modal_Provider>
  </WindowWidthProvider>
);

reportWebVitals();
