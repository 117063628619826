import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Components ----
import Language_Selector from "../components/language_selector";

const ModalContext = createContext();

export function useModal() {
    return useContext(ModalContext);
}

export function Modal_Provider({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [modal_type, set_modal_type] = useState("");
    let [parameters, set_parameters] = useState(null);
    let [prev_offset, set_prev_offset] = useState(0);
   
    let open_modal = (type, modal_parameters) => {
        set_prev_offset(window.pageYOffset);
        window.scrollTo(0, 0);
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
        document.body.style.width = '100%';

        set_show_modal(true);
        set_modal_type(type);
        set_parameters(modal_parameters);
    };

    let close_modal = () => {

        document.body.style.position = '';
        document.body.style.overflow = '';
        document.body.style.width = '';
        window.scrollTo(0, prev_offset);

        set_show_modal(false);
    };

    let context_value = {
        open_modal,
        close_modal
    };


    return (
        <ModalContext.Provider value={context_value}>
            {children}
            { show_modal &&
                (
                    <div className="modal_background">
                        <div className="modal_container">
                        {
                        modal_type == "language_selector" &&
                        <Language_Selector close_modal={close_modal} parameters={parameters}/>
                        }
                       
                        </div>
                    </div>
                )
            }
        </ModalContext.Provider>
    )
}