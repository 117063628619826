import { useState, useEffect } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Components ----
import Header from "../components/header";
import Pre_Footer from "../components/pre_footer";
import Footer from "../components/footer";
import News_Content from "../components/news_content";
import Email_Sign_Up from "../components/email_signup";

// ---- Content ----
import featured from "../content/featured";

function Privacy() {

    let [content, set_content] = useState({
        "title": "Privacy Policy",
        "date": 1714376623,
        "content": <>
        <p>You accept the following Privacy Policy.</p>
        <p><br/></p>
        <p><strong>OVERVIEW</strong></p>
        <br/><p>This website techvoyagernews.com is operated by HYPERION CAPITAL HOLDINGS LTD. Throughout the site, the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo;, refer to HYPERION, also referred to as &ldquo;HYPERION&rdquo;. We offer this website, including all information, tools, and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies, and notices stated here. By visiting our site, you engage in our &ldquo;Service&rdquo; and agree to be bound by this Privacy Policy. Please read this Privacy Policy carefully before accessing or using our website. If you do not agree with this policy, then do not use this website.</p>
        <br/><p>You can review the most current version of this Privacy Policy at any time on this page. We reserve the right to update, change or replace any part of this Privacy Policy by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website after posting any changes constitutes acceptance of those changes.</p>
        <p><br/></p>
        <p><strong>PRIVACY POLICY</strong></p>
        <br/><p>We have adopted this Privacy Policy to guide you through the collection, use, retention, and disclosure of Personally Identifiable Information (&ldquo;Personal Data&rdquo;, as further described below) that you may provide while using HYPERION websites and services in connection with our services (collectively referred to as &ldquo;Services&rdquo;).</p>
        <br/><p>We encourage you to read this Privacy Policy, as well as our Terms of Use and Service, in order to understand how we collect and process Personal Data in the course of providing the Services and your interaction with the Services.</p>
        <br/><p>By using the Services, you consent to the collection, use, retention, and disclosure of your Personal Data for processing as described in, and subject to the limitations set out in, this Privacy Statement.</p>
        <p><br/></p>
        <p><strong>Note to Residents of the European Union:</strong></p>
        <br/><p>To comply with the European General Data Protection Regulation (GDPR) requirements for our European users, this Privacy Statement outlines the legal basis on which we process your Personal Data and provides other information required by the GDPR.</p>
        <p><br/></p>
        <p><strong>Note to Californian residents:</strong></p>
        <br/><p>California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits consumers who are California residents, to request and obtain from us once a year, free of charge, information about the categories of personal information (as defined in the Shine the Light law), if any, that we disclosed in the preceding calendar year to third parties for those third parties&rsquo; direct marketing purposes. Our disclosure requirements apply only if we share our consumers&rsquo; personal information with third parties for them to directly market their own products to those consumers, instead of assisting us with our own business.</p>
        <p><br/></p>
        <p><strong>PURPOSE</strong></p>
        <br/><p>Protecting your privacy is important to us. Personal Data is any information that would identify a person directly or indirectly in combination with data from other sources. For example, a full name, home or work address, phone number, national identification number (SSN, SIN, etc.), email address, banking details, IP address, biometric data, usage data, or any information that may individually identify a person.</p>
        <br/><p>We may collect Personal Data, including without limitation your email address, IP address, and device identifiers and/or geolocation information, in the course of its Services, and we may use or disclose that Personal Data as described with this Privacy Policy.</p>
        <br/><p>We may also create de-identified or anonymized data from Personal Data by excluding data components (such as your name, email address, etc.) that make the data able to identify you through obfuscation or other means personally. In addition, we may collect and use aggregated, anonymous information to provide data about the Services to advertisers, potential business partners, and other unaffiliated entities. As this information does not identify a person and is therefore not Personal Data, HYPERION, when using such aggregated, anonymized, and/or de-identified data, is not subject to this Privacy Statement.</p>
        <br/><p>In addition, when using the Subscription service, you may also voluntarily upload the following, and we may keep the corresponding data:</p>
        <br/>
        <p>&middot; Your email address</p>
        <p>&middot; Your time zone</p>
        <p>&middot; Your IP address</p>
        <br/><p>Collecting this private information allows us to serve you content newsletters, but can also be used for us and our partners to send you promotional material about products and services.</p>
        <p><br/></p>
        <p><strong>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</strong></p>
        <br/><p>When you use our website, we may collect the personal information you voluntarily give us, such as your email address. We may also automatically receive your computer&rsquo;s internet protocol (IP) address, browser information, operating system, and hardware information, time zone, to provide us with information that helps us provide you with a better experience on our website, as well as to prevent the usage of bots. Your consent is implied to collect and use such Data to facilitate that use or complete that transaction initiated by you only.</p>
        <br/><p>If you provide us with your email address, you explicitly agree that we may contact you by email. We may also contact you to inform you of our products and services, as well as from our associated partners. You can opt out of our email list by selecting such a feature on our email messages.</p>
        <p><br/></p>
        <p><strong>SECTION 2 &ndash; CONSENT</strong></p>
        <p><br/></p>
        <p><strong>How do you get my consent?</strong></p>
        <br/><p>If we ask for your personal information for a commercial reason, like for marketing purposes, we will either ask you directly for your expressed consent or give you an opportunity to say no.</p>
        <p><br/></p>
        <p><strong>How do I withdraw my consent?</strong></p>
        <br/><p>If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting our Privacy Compliance Officer (See contact at the end of this page).</p>
        <p><br/></p>
        <p><strong>SECTION 3 &ndash; DISCLOSURE</strong></p>
        <br/><p>We may disclose your personal information to government authorities if we are required by law to do so or if you violate our Terms of Service.</p>
        <p><br/></p>
        <p><strong>SECTION 4 - THIRD-PARTY SERVICE</strong></p>
        <br/><p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.&nbsp;</p>
        <br/><p>The following is a list of Data services engaged by us that may receive Personal Data:</p>
        <br/><p>&middot; GOOGLE</p>
        <p>&middot; FACEBOOK (META) and INSTAGRAM</p>
        <p>&middot; GOOGLE ANALYTICS</p>
        <p>&middot; GOOGLE ADS</p>
        <p>&middot; TWITTER ANALYTICS</p>
        <br/><p>When you click links and/or hyperlinked buttons on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>
        <p><br/></p>
        <p><strong>SECTION 5 &ndash; SECURITY</strong></p>
        <br/><p>To protect your personal information, we take reasonable precautions and follow industry best practices to ensure it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed.</p>
        <p><br/></p>
        <p><strong>SECTION 6 &ndash; COOKIES</strong></p>
        <br/><p>We may use several cookies, and when/if we do, you can choose if you want to opt out of cookies or not.</p>
        <p><br/></p>
        <p><strong>SECTION 7 - TRANSFER OF YOUR PERSONAL DATA TO OTHER COUNTRIES</strong></p>
        <br/><p>The data that we process and data processed by associated services and providers may be stored in servers located in the USA, CANADA, Europe, and/or elsewhere. It may be stored in multiple locations. However, we ensure that regardless of where it is stored, your data is stored in a way consistent with the standards of protection required under applicable law.</p>
        <br/><p>Where Personal Data is transferred from the European Economic Area to a country that has not received an adequacy decision by the European Commission, we rely on appropriate safeguards, such as, for example, the European Commission-approved Standard Contractual Clauses and EU-U.S. Privacy Shield Frameworks, to transfer the Personal Data. By using our Services and submitting your Personal Data, you agree to the transfer, storage, and/or processing your Personal Data in the locations contemplated above. Where and as required, we will seek your explicit consent as outlined in this Privacy Statement.</p>
        <p><br/></p>
        <p><strong>SECTION 8 - CHANGES TO THIS PRIVACY POLICY</strong></p>
        <br/><p>We reserve the right to modify this privacy policy anytime, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated so that you know what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>
        <br/><p>If our website or company is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you. We reserve the right to modify this privacy policy anytime, so please review it frequently.</p>
        <p><br/></p>
        <p><strong>SECTION 9 &ndash; RIGHTS CONCERNING PERSONAL DATA</strong></p>
        <br/><p>We are committed to ensuring you retain full access to and control of your Personal Data. To that end, we endeavor to respect your right to be informed regarding the collection, use, and disclosure of Personal Data and your right to correction and access to it, via this Privacy Statement. If you would like to access, correct, remove, request a copy of, withdraw consent to the collection of your Personal Data, or are looking for any additional information on how your Personal Data may be collected, used, or disclosed by us, please contact our Privacy Compliance Officer (See contact at the end of this page). Subject to certain exceptions and limitations prescribed by applicable law, you will be provided with reasonable access to your Personal Data and will be entitled to have it amended or corrected as appropriate. In certain circumstances, you may have the right to have your Personal Data, or certain components of your Personal Data, erased by us, to have your Personal Data moved, copied, or transmitted from our systems to other systems, or to object to or restrict certain processing of your Personal Data by us. In the event that you wish to inquire about, or seek to exercise any of these rights (as they may be applicable), please contact our Privacy Compliance Officer at:</p>
        <p><br/></p>
        <p>support@hypecapital.co</p>
        </>
    });

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            marginTop: "20px"
            // backgroundColor: colors.dark_blue
        },

        inner_container: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "800px",
            marginLeft: "calc(50% - 600px)",
            // backgroundColor: "red",

            ...(window_width <= 1240 && {
                width: "calc(100% - 440px)",
                marginLeft: "20px",
            }),

            ...(window_width <= 800 && {
                width: "calc(100% - 40px)",
                marginLeft: "20px",
            })
        },

        side_bar: {
            position: "relative",
            display: "inline-block",
            verticalAlign: "top",
            width: "380px",
            marginLeft: "20px",

            ...(window_width <= 800 && {
                display: "none"
            })
        },

        side_bar_inner: {
            borderRadius: "20px",
            marginTop: "20px",
            backgroundColor: colors.light_gray,
            padding: "20px",
            width: "calc(100% - 40px)"
        },

        indent_left: {
            marginLeft: "20px"
        }
    }

    return (
        <>
            <Header/>
            <div style={styles.container}>
                <div style={styles.inner_container}>
                    { window_width > 800 && <h1>{content.title}</h1> }
                    <News_Content data={content}/>
                </div>
                <div style={styles.side_bar}>

                    <h1>Sidebar</h1>
                    <div style={styles.side_bar_inner}>
                        <h2>Newsletter</h2>
                        <Email_Sign_Up/>
                        <p>By signing up to our newsletter, you hereby agree to our Privacy Policy.</p>
                        <br/>
                        <h2>Featured</h2>
                        <br/>
                        <ul style={styles.indent_left}>
                            {
                                featured.map((item, index) => (
                                    <u className="clickable_text" onClick={()=>window.location=item.link}><li key={index}>{item.title}</li><br/></u>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <Pre_Footer/>
            <Footer/>
        </>
    )
}

export default Privacy;