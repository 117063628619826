import { useEffect } from "react";

import Header from "../components/header";
import Article_Section from "../components/article_section";
import Pre_Footer from "../components/pre_footer";
import Footer from "../components/footer";

function Article_Content_Page({ category, title, type }) {
    return (
        <>
            <Header/>
            <Article_Section category={category} title={title} type={type}/>
            <Pre_Footer/>
            <Footer/>
        </>
    )
}

export default Article_Content_Page;