import { useState, useEffect, useRef } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Services ----
import { register_email } from "../services/api";
import { is_valid_email } from "../services/helper_functions";

function Email_Sign_Up() {

    const [is_hovered, set_is_hovered] = useState(false);
    let email_ref = useRef();

    async function save_email() {

        let email_address = email_ref.current.value;

        if (!is_valid_email(email_address)) {
            alert("Please enter a valid email address");
        }
        else {
            try {
                await register_email(email_address);
                email_ref.current.value = "";
                alert("Thank you! We have registered your email address");
            } catch (error) {}
        }
    }

    let styles = {

        container: {
            position: "relative",
            marginTop: "20px",
            width: "100%",
            height: "70px",
            // backgroundColor: "black",
            color: "white",
            maxWidth: "500px"

        },

        email_input: {
            position: "absolute",
            width: "calc(100% - 120px)",
            padding: "10px",
            height: "30px",
            border: `1px solid ${colors.light_gray}`
        },

        sign_up_button: {
            position: "absolute",
            right: "0px",
            width: "80px",
            backgroundColor: is_hovered ? "#c7c7c7" : ` ${colors.light_gray}`,
            // color: "white",
            color: "black",
            fontWeight: "bold",
            height: "50px",
            lineHeight: "50px",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "14px"
        }

    }

    return (
        <div style={styles.container}>
            <input style={styles.email_input} placeholder="Email address" type="email" ref={email_ref}/>
            <div
                style={styles.sign_up_button}
                onClick={save_email}
                onMouseEnter={() => set_is_hovered(true)}
                onMouseLeave={() => set_is_hovered(false)}
                onTouchStart={() => set_is_hovered(true)}
                onTouchEnd={() => set_is_hovered(false)}
            >JOIN</div>
        </div>
    )
}

export default Email_Sign_Up;