import { useState, useEffect } from 'react';

// ---- Constants ----
import colors from "../constants/colors";

// ---- Hooks ----
import { useWindowWidth } from '../services/window_width_provider';

// ---- Components ----;
import Email_Sign_Up from "./email_signup";

function Pre_Footer() {

    const window_width = useWindowWidth();

    let styles = {

        container: {
            position: "relative",
            width: "100%",
            marginTop: "20px",
            borderTop: `1px solid ${colors.light_gray}`
        },

        inner_container: {
            position: "relative",
            width: "1200px",
            marginLeft: "calc(50% - 600px)",
            height: "100%",
            paddingTop: "20px",
            paddingBottom: "20px",

            ...(window_width <= 1240 && {
                width: "calc(100% - 40px)",
                marginLeft: "20px",
            })
        },
    
    }

    return (
        <div style={styles.container}>
            <div style={styles.inner_container}>
                <h3>Newsletter</h3>
                <Email_Sign_Up/>
                <p style={{fontSize: "14px"}}>By signing up to our newsletter, you hereby agree to our Privacy Policy.</p>
            </div>
        </div>
    )
}

export default Pre_Footer;